import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import GetErrors from '@/theme/components/GetErrors'
import store from './store'

// toast plugin
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'

// datatable
import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net'
import 'datatables.net-select'
import 'datatables.net-responsive'
DataTable.use(DataTablesCore)

router.beforeEach((to, from, next) => {
  const isAdmin = store.state.auth.isAdmin
  if (to.name !== 'Login' && !isAdmin) next({ name: 'Login' })
  else if (to.name === 'Login' && isAdmin) next({ name: 'Dashboard' })
  else next()
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(ToastPlugin)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)
app.component('GetErrors', GetErrors)
app.component('DataTable', DataTable)

app.mount('#app')
