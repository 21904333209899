<template>
  <CAlert color="danger" v-if="errors.length">
    <p v-for="error in errors" :key="error.$uid">
      {{ error.$property.toUpperCase() }} - {{ error.$message.toLowerCase() }}
    </p>
  </CAlert>
</template>

<script>
export default {
  props: ['errors'],
  name: 'GetErrors',
}
</script>
