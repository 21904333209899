import store from '@/store'
import axios from 'axios'
import { useToast } from 'vue-toast-notification'

const { VUE_APP_API_URL } = process.env
const $toast = useToast()

const instance = axios.create({
  baseURL: VUE_APP_API_URL,
})

instance.interceptors.request.use(
  async (config) => {
    // set loader
    store.commit('setIsLoading', true)
    console.log(store.state.isLoading)

    const token = store.state.auth.token
    console.log(token)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    // console.log(config.headers)
    return config
  },
  (error) => {
    // set loader
    store.commit('setIsLoading', false)

    // Handle request errors
    console.error('Axios Request Error:', error)
    new Error('Timeout, Server is not responding')
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (res) => {
    // set loader
    store.commit('setIsLoading', false)

    return res.data || []
  },
  (error) => {
    // set loader
    store.commit('setIsLoading', false)

    if (error.response) {
      const data = error.response.data || []
      const errors = data?.errors || []

      if (errors.length) {
        $toast.open({
          message: errors[0],
          type: 'error',
        })
        return
      }

      if (data?.message) {
        $toast.open({
          message: data?.message,
          type: 'error',
        })
      }

      return Promise.reject(data)
    } else if (error.request) {
      $toast.open({
        message:
          'Something went wrong, please try again or contact laundry service.',
        type: 'error',
      })
      console.error('Request error:', error.request)
    } else {
      $toast.open({
        message:
          'Something went wrong, please try again or contact laundry service.',
        type: 'error',
      })
      console.error('Request setup error:', error.message)
    }
    return Promise.reject(error)
  },
)

export default instance
