import { createStore } from 'vuex'
import { authStoreModule } from './modules/auth'
import { productStoreModule } from './modules/product'
import { orderStoreModule } from './modules/order'
import createPersistedState from 'vuex-persistedstate'

const debug = process.env.NODE_ENV === 'production'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    errors: [],
    isLoading: false,
  },
  mutations: {
    toggleSidebar: (state) => (state.sidebarVisible = !state.sidebarVisible),
    toggleUnfoldable: (state) =>
      (state.sidebarUnfoldable = !state.sidebarUnfoldable),
    updateSidebarVisible: (state, payload) =>
      (state.sidebarVisible = payload.value),
    setErrors: (state, errors) => (state.errors = errors),
    setIsLoading: (state, isLoading) => (state.isLoading = isLoading),
  },
  actions: {},
  modules: {
    auth: authStoreModule,
    product: productStoreModule,
    order: orderStoreModule,
  },
  plugins: [createPersistedState()],
  strict: debug,
})
