import axios from '@/config/axios-config'

export const authStoreModule = {
  namespaced: true,

  state: {
    user: null,
    token: null,
    isAdmin: false,
    adminUsers: [],
  },

  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    isAdmin: (state) => state.isAdmin,
    adminUsers: (state) => state.adminUsers,
  },

  actions: {
    async getAdminUsers({ commit }) {
      try {
        const res = await axios.get('admin/users')
        commit('setAdminUsers', res.data)
      } catch (e) {
        console.log('Error: ', e)
        commit('setAdminUsers', [])
      }
    },
    async setUserRole({ commit }, payload) {
      try {
        await axios.post('admin/users/set-role', payload)
        commit('setUserRole', null)

        const res = await axios.get('admin/users')
        commit('setAdminUsers', res.data)
        // this.$toast.open('User role updated')
      } catch (e) {
        // this.$toast.open('Something went wrong')
        console.log('Error: ', e)
      }
    },
    async login({ commit }, payload) {
      try {
        const { data } = await axios.post('auth/user/login', payload)
        if (data?.user && data?.token) {
          commit('setUser', data.user)
          commit('setToken', data.token)
          commit('setIsAdmin', data.user.role === 'ADMIN')
        }
        return data
      } catch (e) {
        console.log('Error: ', e)
        commit('setUser', null)
        commit('setToken', null)
        commit('setIsAdmin', false)
      }
    },

    async logout({ commit }) {
      commit('setUser', null)
      commit('setToken', null)
      commit('setIsAdmin', false)
    },
  },

  mutations: {
    setUser: (state, payload) => (state.user = payload),
    setToken: (state, payload) => (state.token = payload),
    setIsAdmin: (state, payload) => (state.isAdmin = payload),
    setAdminUsers: (state, payload) => (state.adminUsers = payload),
  },
}
