<template>
  <CFooter>
    <div>
      By
      <a href="https://muhammadsikandar.com" target="_blank"
        >Muhammad Sikandar</a
      >
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
