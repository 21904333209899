import axios from '@/config/axios-config'

export const productStoreModule = {
  namespaced: true,

  state: {
    products: [],
    updatedProduct: null,
  },

  getters: {
    products: ({ products }) => products,
  },

  actions: {
    async getProducts({ commit }) {
      try {
        const res = await axios.get('products')
        commit('setProducts', res.data)
      } catch (e) {
        console.log('Error: ', e)
        commit('setProducts', [])
      }
    },

    async updateProduct({ commit }, payload) {
      commit('setUpdatedProduct', null)
      try {
        const res = await axios.post(
          'player/update-player-result-data',
          payload,
        )
        commit('setUpdatedProduct', res.data)
      } catch (e) {
        console.log('Error: ', e)
        commit('setUpdatedProduct', null)
      }
    },
  },

  mutations: {
    setProducts: (state, payload) => (state.products = payload),
    setUpdatedProduct: (state, payload) => (state.updatedProduct = payload),
  },
}
