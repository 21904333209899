import axios from '@/config/axios-config'

export const orderStoreModule = {
  namespaced: true,

  state: {
    orders: [],
    updatedOrder: null,
    orderDetail: null,
  },

  getters: {
    orders: ({ orders }) => orders,
    updatedOrder: ({ updatedOrder }) => updatedOrder,
    orderDetail: ({ orderDetail }) => orderDetail,
  },

  actions: {
    async getOrders({ commit }) {
      try {
        const res = await axios.get('admin/orders')
        commit('setOrders', res.data)
      } catch (e) {
        console.log('Error: ', e)
        commit('setOrders', [])
      }
    },
    async getOrderDetail({ commit }, payload) {
      try {
        const res = await axios.get(`admin/orders/detail/${payload.id}`)
        commit('setOrderDetail', res.data)
      } catch (e) {
        console.log('Error: ', e)
        commit('setOrderDetail', {})
      }
    },
    async updateOrder({ commit }, payload) {
      try {
        const res = await axios.post('admin/orders/update-order', payload)
        commit('setUpdatedOrder', res.data)
      } catch (e) {
        commit('setUpdatedOrder', null)
      }
    },
  },

  mutations: {
    setOrders: (state, payload) => (state.orders = payload),
    setUpdatedOrder: (state, payload) => (state.updatedOrder = payload),
    setOrderDetail: (state, payload) => (state.orderDetail = payload),
  },
}
